<template>
  <div class="c-box" :class="{'is-collapsed': collapse}">
    <header>
      <h2>{{title}}</h2>
      <svg @click="collapse = !collapse" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
    </header>
    <div class="content">
      <slot></slot>
    </div>
    <template v-if="$slots['info']">
      <div class="c-box__information" @click="popupIsActive = true">
        i
      </div>
      <b-modal :active.sync="popupIsActive" :width="600">
          <div class="card">
            <slot name="info"></slot>
          </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
export default {
  props: ['title'],
  data() {
    return {
      collapse: false,
      popupIsActive: false,
    }
  }
}
</script>

<style lang="scss">
  .c-box {
    margin-bottom: 2rem;
    position: relative;

    header {
      padding: 10px;
      color: white;
      background-color: $primary;
      display: flex;
      position: relative;
      min-height: 35px;
    }

    h2 {
      margin: 0;
      padding: 0;
      line-height: 1.2;
      font-size: 1rem;
      flex: 1 0 auto;
      font-weight: bold;
    }

    header svg{
      cursor: pointer;
      transform: rotate(0);
      transition: transform 0.2s ease;
      position: absolute;
      right: 10px;
      top: 7px;
    }

    .content {
      padding: 1rem;
      background: white;
    }

    &.is-collapsed .content{
      display: none;
    }

    &.is-collapsed header svg{
      transform: rotate(180deg)
    }
  }

  .c-box__information {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 800;
    font-size: 18px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    position: absolute;
    left: -34px;
    top: 4px;
    background: $primary;
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    transition: background 0.2s ease;
    cursor: pointer;

    &:hover {
      background: var(--primary--dark);
    }
  }
</style>