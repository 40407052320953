<template>
   <div class="c-step-intro">
     <div class="c-step-intro__content">
      <p>
        Thank you for completing our thesis wizard. This information will now be included in your dissertation, of which you will receive a proof as soon as possible.<br>
        <br>
        You can send changes or remarks on this pdf proof to studio@ridderprint.nl  stating your order number.<br> 
        As soon as you approve your pdf proof we can proceed to print your paper proof.<br>
        <br>
        You can find all information related to your dissertation in this portal. By clicking on your order number at the top left you can find information such as the status of your order, the ISBN number and the planned dates.
      </p>
     </div>
  </div>    
</template>

<script>
export default {
}
</script>

<style>

</style>