<template>
  <div>
    <step-intro iframe-url="">
        <p>Please download the PDF below and take a look at our different templates. Select the template you want and choose which elements you want to add to your PDF layout.</p>
    </step-intro>
    <validation-observer tag="div" :step-index="stepIndex">
        <box title="Template and elements">
            <p>To choose your template please download our PDF with templates:</p>
            <a href="https://www.ridderprint.nl/wp-content/uploads/2024/07/showcase.pdf" target="_blank" class="button is-primary">Download the PDF with templates</a>
            <br><br>
            <div class="columns is-vcentered">
                <div class="column is-narrow has-text-grey-dark">I choose:</div>
                <div class="column">
                    <validation-provider rules="required" v-slot="{ errors }">
                        <b-field :message="errors" :type="{ 'is-danger': errors[0] }">
                            <b-select placeholder="Select a template" v-model="selectedTemplate">
                                <option
                                    v-for="option in templateOptions"
                                    :value="option.id"
                                    :key="option.id">
                                    {{ option.label }}
                                </option>
                            </b-select>
                        </b-field>
                    </validation-provider>
                </div>
            </div>
            <p>I want to add the following elements:</p>
            <div class="field field--small">
                <b-checkbox @input="maybeUpdateTabs" v-model="headerAndPageNumbers">Headers and pagenumbers <a @click="headersAndPageNumberModal = true" href="#">example</a>
                </b-checkbox>
            </div>
            <div class="field field--small">
                <b-checkbox @input="maybeUpdateTabs" v-model="tabs">Tabs <a @click="tabsModal = true" href="#">example</a>
                </b-checkbox>
            </div>
            <div class="field field--small">
                <b-checkbox @input="maybeUpdateTabs" v-model="chapterPages">Chapterpages <a @click="chapterPagesModal = true" href="#">example</a>
                </b-checkbox>
            </div>
        </box>
    </validation-observer>
    <b-modal :active.sync="headersAndPageNumberModal" :width="800">
        <div class="card">
            <div class="card-image">
                <img src="@/assets/headers-and-pagenumbers.jpg">
            </div>
            <div class="card-content">
                <div class="content">
                    <p>We will place headers on top of every left and right page. You can use different headers for every chapter and every appendix. In step 5 you can fill in the headings you want to use.</p>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal :active.sync="tabsModal" :width="800">
        <div class="card">
            <div class="card-image">
                <img src="@/assets/tabs.jpg">
            </div>
            <div class="card-content">
                <div class="content">
                    <p>We will place ‘thumb tabs’ on the right side of the page. Every chapter has the number on it, for appendices we put an ‘A’ in the tab. Except the structure in step 3 we don’t need any information from you.</p>
                </div>
            </div>
        </div>
    </b-modal>
    <b-modal :active.sync="chapterPagesModal" :width="800">
        <div class="card">
            <div class="card-image">
                <img src="@/assets/chapter-pages.jpg">
            </div>
            <div class="card-content">
                <div class="content">
                    <p>We will replace the two chapter pages in your PDF with the one you choose in the template. In step 5 you can enter the chapter title, the authors and details of your publication.</p>
                </div>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import box from './../components/box.vue';
import stepIntro from './../components/step-intro.vue';
import ValidationObserver from './../components/validation-observer.vue';

export default {
    props: ['stepIndex'],
    components: {
        box,
        stepIntro,
        ValidationObserver
    },
    computed: {
        ...mapFields(['steps', 'templateOptions.selectedTemplate', 'templateOptions.elements.headerAndPageNumbers', 'templateOptions.elements.tabs', 'templateOptions.elements.chapterPages']),
    },
    data() {
        return {
            headersAndPageNumberModal: false,
            tabsModal: false,
            chapterPagesModal: false,
            templateOptions: [
                {
                    id: '',
                    label: 'Select a template',
                },
                {
                    id: 'template-1',
                    label: 'Template 1',
                },
                {
                    id: 'template-2',
                    label: 'Template 2',
                },
                {
                    id: 'template-3',
                    label: 'Template 3',
                },
                {
                    id: 'template-4',
                    label: 'Template 4',
                },
                {
                    id: 'template-5',
                    label: 'Template 5',
                },
                {
                    id: 'template-6',
                    label: 'Template 6',
                },
                {
                    id: 'template-7',
                    label: 'Template 7',
                }
            ]
        }
    },

    methods: {
        maybeUpdateTabs() {
            const onlyTabsSelected = this.tabs && !this.headerAndPageNumbers && !this.chapterPages;
            const detailsStep = this.steps.find(step => step.type === 'thesisDetails');
            if (detailsStep) {
                detailsStep.visible = !onlyTabsSelected;
            }
        }
    }
}
</script>

<style>

</style>