import { required, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "This field is required"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});

extend("url", {
  validate(value) {
    //eslint-disable-next-line
    const regex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/);
    return value.match(regex);
  },
  message: "This field must be a valid url"
});

extend("max", {
  params: ['max'],
  validate(value, { max }) {
    return value.length <= max;
  },
  message: "The text of this field should be {max} characters or less"
});

extend("higher_than", {
  params: ['min'],
  validate(value, { min }) {
    return parseInt(value) > parseInt(min);
  },
  message: "This field should be an number higher than {min}"
});


extend("odd", {
  validate(value) {
    return value % 2 !== 0;
  },
  message: "This field should be an odd number"
});

extend('last_page', {
  params: ['target'],
  validate(value, { target }) {
    const val  = parseInt(value);
    return val >= parseInt(target) && val > 0;
  },
  message: 'the last page should be higher than the start page'
});