<template>
    <box class="c-chapterbox" :title="title ? title : 'Chapter ' +  (index + 1)">
        <template v-if="chapterPages">
          <div class="field">
            <label>Chapter title</label>
            <b-input v-model="chapter.title"></b-input>
          </div>
          <div class="columns mb-0">
            <div class="column">
              <div class="field">
                <label>Authors <span class="has-text-grey">(Separate authors with a comma)</span></label>
                <b-input placeholder="Separate the authors like this: Author 1, Author 2, etc." v-model="chapter.authors" type="textarea" rows="2"></b-input>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label>Publication details</label>
                <b-input v-model="chapter.publisher" type="textarea" rows="2"></b-input>
              </div>
            </div>
          </div>
        </template>
        <hr v-if="headerAndPageNumbers && chapterPages">
        <div v-if="headerAndPageNumbers">
          <div class="field">
            <div class="columns mb-0">
              <div class="column pb-0">
                <label>Left header <span class="has-text-grey">(maximum of 80 characters incl. spaces)</span></label>
              </div>
              <div class="column is-narrow pb-0">
                <char-counter class="u-text-right" :chars-used="chapter.leftHeader.length" total-chars="80"></char-counter>
              </div>
            </div>
             <validation-provider rules="max:80" v-slot="{ errors }">
                <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                    <b-input placeholder="In general ‘Chapter X’ is placed here.." v-model="chapter.leftHeader"></b-input>
                </b-field>
            </validation-provider>
          </div>
          <div class="field">
            <div class="columns mb-0">
              <div class="column pb-0">
                <label>Right header <span class="has-text-grey">(maximum of 80 characters incl. spaces)</span></label>
              </div>
              <div class="column is-narrow pb-0">
                <char-counter class="u-text-right" :chars-used="chapter.rightHeader.length" total-chars="80"></char-counter>
              </div>
            </div>
            <validation-provider rules="max:80" v-slot="{ errors }">
                <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                    <b-input placeholder="In general there is a short title placed here.." v-model="chapter.rightHeader"></b-input>
                </b-field>
            </validation-provider>
          </div>
        </div>
    </box>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import box from './box.vue';
import charCounter from './char-counter.vue';

export default {
    props: ['chapter', 'index', 'title', 'stepIndex'],
    components: {
        box,
        charCounter,
    },
    computed: {
      ...mapFields(['templateOptions.elements.headerAndPageNumbers', 'templateOptions.elements.chapterPages']),
    },
}
</script>

<style lang="scss">
.c-chapterbox {
  .textarea {
    resize: none;
  }

  hr {
    background-color: transparent;
    background-image: linear-gradient(to right, #ddd 60%, rgba(255, 255, 255, 0) 40%);
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    margin: 0 -1rem 0.7rem -1rem;
  }
}
</style>