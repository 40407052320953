<template>
  <div>
    <step-intro iframe-url="">
        <p>You submitted all information we need to create a proper PDF layout. When you submit your PDF layout we will send you a proof as soon as possible. If we have any questions we will get in touch with you.</p>
    </step-intro>
    <validation-observer tag="div" :step-index="stepIndex">
        <box title="Contact details">
            <div class="columns is-multiline">
                <div class="column">
                    <validation-provider rules="required" v-slot="{ errors }">
                        <b-field label="Firstname" :type="{ 'is-danger': errors[0]}" :message="errors">
                            <b-input v-model="contact.firstName"></b-input>
                        </b-field>
                    </validation-provider>
                </div>
                <div class="column">
                    <validation-provider rules="required" v-slot="{ errors }">
                        <b-field label="Lastname" :type="{ 'is-danger': errors[0]}" :message="errors">
                            <b-input v-model="contact.lastName"></b-input>
                        </b-field>
                    </validation-provider>
                </div>
                <div class="column">
                    <validation-provider rules="required|email" v-slot="{ errors }">
                        <b-field label="Email" :type="{ 'is-danger': errors[0]}" :message="errors">
                            <b-input v-model="contact.email" type="email"></b-input>
                        </b-field>
                    </validation-provider>
                </div>
                <div class="column is-full">
                    <b-field label="Remarks">
                        <b-input type="textarea" v-model="contact.remarks"></b-input>
                    </b-field>
                </div>
            </div>
            <validation-provider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                <b-field class="field--small" :type="{ 'is-danger': errors[0]}" :message="errors">
                    <b-checkbox v-model="AgreeWithPrivacy">I agree with the <a href="https://www.ridderprint.nl/privacy-en-cookieverklaring/" target="_blank">privacy policy</a>.</b-checkbox>
                </b-field>
            </validation-provider>
        </box>
    </validation-observer>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import box from './../components/box.vue';
import stepIntro from './../components/step-intro.vue';
import ValidationObserver from './../components/validation-observer.vue';

export default {
    props: ['stepIndex'],
    components: {
        box,
        stepIntro,
        ValidationObserver
    },
    computed: {
        ...mapFields(['contact']),
    },
    data() {
        return {
            AgreeWithPrivacy: false,
        }
    }
}
</script>