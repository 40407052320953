<template>
    <div class="c-step-intro">
        <div class="columns">
            <div class="column">
                <div class="c-step-intro__content">
                    <p>
                        <slot></slot>
                    </p>
                </div>
            </div>
            <div class="column c-step-intro__video is-narrow" v-if="iframeUrl">
                <div class="iframe-container"><iframe :src="iframeUrl + '?color=ffffff&portrait=0&title=0&sidedock=0'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['iframeUrl'],
}
</script>

<style lang="scss">
    .c-step-intro {
        border-top: 1px solid gray;
        border-bottom: 1px solid gray;
        margin-bottom: 2rem;

    }

    .c-step-intro__video  {
        width: 408px;
    }

    .c-step-intro__content {
        padding: 2rem 0;

    }

    .iframe-container {
        padding:56.25% 0 0 0;
        position:relative;

        iframe {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }

</style>