<template>
    <span class="c-charcounter" :class="remainingChars >= 0 ? 'has-text-success' : 'has-text-danger'">
        {{remainingChars}}
    </span>
</template>
<script>
export default {
    props: ['charsUsed', 'totalChars'],
    computed: {
        remainingChars() {
            return this.totalChars - this.charsUsed;
        }
    }
}
</script>
