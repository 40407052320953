<template>
  <div>
    <step-intro iframe-url="">
       <p>To be able to place your chosen on elements in the right place we need to know more about the structure of your thesis. Please fill in the information below.</p>
    </step-intro>
    <template>
        <validation-observer tag="div" :step-index="stepIndex">
            <box title="Structure - Chapters, parts, appendices">
                <div class="columns is-vcentered">
                    <div class="column">
                        <label class="label">How many <strong>pages</strong> does the inside of your thesis consists of?</label>
                    </div>
                    <div class="column is-narrow" style="width: 200px;">
                        <validation-provider rules="higher_than:0" v-slot="{ errors }">
                            <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                                <b-input v-model="pagesAmount" type="number"></b-input>
                            </b-field>
                        </validation-provider>
                    </div>
                    <div class="column">
                    <i>Count only pages with content. We will add blank pages at the end of your thesis automatically when this is necessary.</i>
                    </div>
                </div>
                <hr>
                <div class="columns is-vcentered">
                    <div class="column">
                        <label class="label">How many <strong>chapters</strong> does your thesis contain? (excluding appendices)</label>
                    </div>
                    <div class="column is-narrow" style="width: 200px;">
                        <div class="field">
                            <b-select expanded v-model="chaptersAmount">
                                <option value="0">No chapters</option>
                                <option v-for="option in 20" :value="option" :key="'chaptersAmount-' + option">
                                    {{ option }} {{ option === 1 ? 'chapter' :  'chapters' }}
                                </option>
                            </b-select>
                        </div>
                    </div>
                    <div class="column">
                        <i>Subchapters (2.1, 2.2, etc.) count as one chapter and will be addressed later on in the proces.</i>
                    </div>
                </div>
                <hr>
                <div class="columns is-vcentered">
                    <div class="column">
                        <label class="label">How many <strong>parts</strong> does your thesis contain?</label>
                    </div>
                    <div class="column is-narrow" style="width: 200px;">
                        <div class="field">
                            <b-select expanded v-model="partsAmount">
                                <option value="0">No parts</option>
                                <option value="2">2 parts</option>
                                <option value="3">3 parts</option>
                                <option value="4">4 parts</option>
                                <option value="5">5 parts</option>                        
                            </b-select>
                        </div>
                    </div>
                    <div class="column">
                        <i>A part within a thesis contains several chapters.</i>
                    </div>
                </div>
                <div class="columns is-vcentered" v-if="partsAmount && partsAmount !== '0'">
                    <div class="column">
                        <label class="label">What part notation do you want?</label>
                    </div>
                    <div class="column is-narrow" style="width: 200px;">
                        <div class="field">
                            <div class="field">
                                <b-select expanded v-model="partsNotion">
                                    <option value="arabic">1,2,3</option>
                                    <option value="roman">I, II, III</option>
                                </b-select>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <i>This notation is used on your part page</i>
                    </div>
                </div>
                <hr>
                <div class="columns is-vcentered">
                    <div class="column">
                        <label class="label">How many <strong>appendices</strong> does your thesis contain?</label>
                    </div>
                    <div class="column is-narrow" style="width: 200px;">
                        <div class="field">
                            <b-select expanded v-model="appendicesAmount">
                                <option value="0">No appendices</option>                        
                                <option v-for="option in 10" :value="option" :key="'appendicesAmount-' + option">
                                    {{ option }} {{ option === 1 ? 'appendice' :  'appendices' }}
                                </option>
                            </b-select>
                        </div>
                    </div>
                    <div class="column">
                        <i>I.e.: Summary, CV, Publications, etc.</i>
                    </div>
                </div>
            </box>
        </validation-observer>
    </template>
    <b-modal :active.sync="notationModal" :width="800">
        <div class="card">
            <div class="card-image">
                <img src="@/assets/chapter-pages.jpg">
            </div>
            <div class="card-content">
                <div class="content">
                    <p>You can choose between 1,2,3,4 etc. or I, II, III, IV, etc. as the notation for your parts.</p>
                </div>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import box from './../components/box.vue';
import stepIntro from './../components/step-intro.vue';
import ValidationObserver from './../components/validation-observer.vue';

export default {
    props: ['stepIndex'],
    components: {
        box,
        stepIntro,
        ValidationObserver
    },
    data() {
        return {
            notationModal: false,
        }
    },
    computed: {
        chaptersAmount: {
            get() {
                return this.$store.state.structure.chapters.length;
            },
            set(value) {
                this.$store.commit('updateChapterAmount', value)
            }
        },
        partsAmount: {
            get() {
                return this.$store.state.structure.parts.length;
            },
            set(value) {
                this.$store.commit('updatePartsAmount', value)
            }
        },
        appendicesAmount: {
            get() {
                return this.$store.state.structure.appendices.length;
            },
            set(value) {
                this.$store.commit('updateAppendicesAmount', value)
            }
        },
        ...mapFields(['structure.pagesAmount', 'structure.partsNotion', 'templateOptions.elements.chapterPages', 'templateOptions.elements.headerAndPageNumbers', 'templateOptions.elements.tabs']),
    },
}
</script>