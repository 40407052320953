<template>
  <span></span>
</template>
<script>
export default {
  /**
   * This component is a hack.
   * The validationObserver of vee-validate has scoped slots and emits no events on validation state changes
   * So we can't listen to the validation change
   * 
   * But we can insert a component in the validationObserver and listen for props changes triggered by changes in the scoped slot
   * So that way we can make the scoped slot variabels available in our store
   * See https://forum.vuejs.org/t/watch-slot-props/63228/5
   */
  props: ['valid', 'stepIndex'],
  watch: {
    valid:{
      immediate: true,
      handler () {        
        this.$store.commit('updateStepValidity', {valid: this.valid, stepIndex: this.stepIndex})
      }
    }
  }
}
</script>