<template>
  <validation-observer v-slot="{ valid }">
      <slot></slot>
      <validation-watcher :valid="valid" :step-index="stepIndex"></validation-watcher>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import validationWatcher from './../components/validation-watcher.vue';

export default {
    props: ['stepIndex'],
    components: {
        ValidationObserver,
        validationWatcher,
    }
}
</script>

<style>

</style>