import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist'

// Import the `getField` getter and the `updateField`
// mutation function from the `vuex-map-fields` module.
import { getField, updateField } from 'vuex-map-fields';
import {createBlancoPart, createChapterIntro, createChapter, createAppendixIntro, createAppendix, getPartIndexThatStartWithThisChapter, createPart} from './indesign';

const vuexPersist = new VuexPersist({
  key: 'thesis-template-creator',
  storage: window.localStorage
})

Vue.use(Vuex);
 
export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    activeStep: 0,
    biggestStep: 0,
    steps: [
      {
        label: '1 - Template',
        type: 'thesisTemplate',
        valid: false,
        visible: true,
      },
      {
        label: '2 - Upload',
        type: 'upload',
        valid: false,
        visible: true,
      },
      {
        label: '3 - Structure',
        type: 'structure',
        valid: false,
        visible: true,
      },
      {
        label: '4 - Contents',
        type: 'contents',
        valid: false,
        visible: true,
      },
      {
        label: '5 - Details',
        type: 'thesisDetails',
        valid: true,
        visible: false,
      },
      {
        label: '6 - Contact',
        type: 'contact',
        valid: false,
        visible: true,
      },
    ],
    thesisFile: null,
    uploadRequirements: {
        watchedVideo: false,
        chaptersUneven: false,
        layoutCorrect: false,
    },
    templateOptions: {
        selectedTemplate: '',
        elements: {
            headerAndPageNumbers: false,
            tabs: false,
            chapterPages: false,
        }
    },
    structure: {
        pagesAmount: 0,
        partsNotion: 'arabic',
        chapters: [],
        parts: [],
        appendices: [],
    },
    contact: {
        firstName: '',
        lastName: '',
        email: '',
        remarks: '',
    }
 },
 getters: {
   indesignJSON: state => {
      const indesignJson =  {
        template: state.templateOptions.selectedTemplate,
        'header-and-pageNumbers': state.templateOptions.elements.headerAndPageNumbers,
        'tabs': state.templateOptions.elements.tabs,
        'chapter-pages': state.templateOptions.elements.chapterPages,
        'total-pages': state.structure.pagesAmount,
        contents: []
      };

      // When we have chapters, include them
      if (state.structure.chapters.length > 0) {

        /*
         * Fist set the blanco part, the first chapter.
         */ 
        const firstPartChapterStart = state.structure.chapters[0].firstPage || 3;
        indesignJson.contents.push(createBlancoPart(firstPartChapterStart));
        
        /**
         * Loop through the chapters
         */
        state.structure.chapters.forEach((chapter, index) => {

          // 1. If this there is a part that starts with this chapter, get that first
          const partIndexThatStartWithThisChapter = getPartIndexThatStartWithThisChapter(index, state);          
          if (partIndexThatStartWithThisChapter !== false) {
            indesignJson.contents.push(createPart(chapter, partIndexThatStartWithThisChapter, state));
          }

          // 2. create an intro spread called 'chapter page'
          indesignJson.contents.push(createChapterIntro(chapter, index));

          // 3. Include the real chapter
          indesignJson.contents.push(createChapter(chapter, index, state));

          // Do the same for subchapters
          if (chapter.subChapters && chapter.subChapters.length) {
            chapter.subChapters.forEach((subChapter, subChapterIndex) => {
              indesignJson.contents.push(createChapterIntro(subChapter, index, true, subChapterIndex));
              indesignJson.contents.push(createChapter(subChapter, index, state, true, subChapterIndex));
            });
          }
        });

      }

      // When we have appendices, include them
      if (state.structure.appendices.length > 0) {
        indesignJson.contents.push(createAppendixIntro(state));
        state.structure.appendices.forEach(appendix => {          
          indesignJson.contents.push(createAppendix(appendix))
        });
      }
      return indesignJson;
   },
    // Add the `getField` getter to the
    // `getters` of your Vuex store instance.
    getField,
  },
  mutations: {

    updateActiveStep(state, index) {
      state.activeStep = index;
    },

    updateBiggestStep(state, index) {
      state.biggestStep = index;
    },

    /*
     * Updat the chapterAmount array based on a number
     */
    updateChapterAmount(state, amount) {
      // If new amount is more than the current length, add empty chapters
      if (amount > state.structure.chapters.length) {
        const amountToAdd = amount - state.structure.chapters.length;
        for(let i = 0; i < amountToAdd; i++){
            state.structure.chapters.push({
                firstPage: 0,
                title: '',
                authors: '',
                publisher: '',
                leftHeader: '',
                rightHeader: '',
                subChapters: []
            });
        }
      }
      // If the new amount is less, strip chapters
      if (amount < state.structure.chapters.length) {
        const amountToDelete = state.structure.chapters.length - amount;
        state.structure.chapters.splice(-amountToDelete, amountToDelete);

      }
    },

    /*
     * Updat the subChapterAmount array based on a number
     */
    updateSubChapterAmount(state, {amount, parentChapterIndex}) {
      // If new amount is more than the current length, add empty chapters
      if (!state.structure.chapters[parentChapterIndex] || !state.structure.chapters[parentChapterIndex].subChapters) return false;
      const parentChapter = state.structure.chapters[parentChapterIndex];

      if (amount > parentChapter.subChapters.length) {
        const amountToAdd = amount - parentChapter.subChapters.length;
        for(let i = 0; i < amountToAdd; i++){
            parentChapter.subChapters.push({
                firstPage: 0,
                title: '',
                authors: '',
                publisher: '',
                leftHeader: '',
                rightHeader: '',
            });
        }
      }
      // If the new amount is less, strip chapters
      if (amount < parentChapter.subChapters.length) {
        const amountToDelete = parentChapter.subChapters.length - amount;
        parentChapter.subChapters.splice(-amountToDelete, amountToDelete);
      }
    },

    /*
     * Updat the parts amount array based on a number
     */
    updatePartsAmount(state, amount) {
      // If new amount is more than the current length, add empty chapters
      if (amount > state.structure.parts.length) {
        const amountToAdd = amount - state.structure.parts.length;
        for(let i = 0; i < amountToAdd; i++){
            state.structure.parts.push({
                startsWithChapter: 0,
                title: '',
            });
        }
      }
      // If the new amount is less, strip chapters
      if (amount < state.structure.parts.length) {
        const amountToDelete = state.structure.parts.length - amount;
        state.structure.parts.splice(-amountToDelete, amountToDelete);
      }
    },

    /*
     * Updat the parts amount array based on a number
     */
    updateAppendicesAmount(state, amount) {
      // If new amount is more than the current length, add empty chapters
      if (amount > state.structure.appendices.length) {
        const amountToAdd = amount - state.structure.appendices.length;
        for(let i = 0; i < amountToAdd; i++){
            state.structure.appendices.push({
                firstPage: 0,
                lastPage: 0,
                title: '',
                leftHeader: '',
                rightHeader: '',
            });
        }
      }
      // If the new amount is less, strip chapters
      if (amount < state.structure.appendices.length) {
        const amountToDelete = state.structure.appendices.length - amount;
        state.structure.appendices.splice(-amountToDelete, amountToDelete);
      }
    },

    /**
     * Update the appendice title on change
     * update the appendice left and right header with the title when not touched
     * 
     * @param {object} state 
     * @param {object{type: string, index: number, value: string}}  
     */
    updateAppendiceHeader(state, {index, value}) {
      let currentAppendice = state.structure.appendices[index];
      if (!currentAppendice) return;
      const oldTitle = currentAppendice.title 
      currentAppendice.title = value;

      // Update left and right header when they are the same as the old title
      if (!currentAppendice.leftHeader || currentAppendice.leftHeader === oldTitle) {
        currentAppendice.leftHeader = value;
      }

      // Update left and right header when they are the same as the old title
      if (!currentAppendice.rightHeader || currentAppendice.rightHeader === oldTitle) {
        currentAppendice.rightHeader = value;
      }
    },

    /**
     * Check if a step is valid based on the validity given by the vee-validate ValidationObserver component
     * Used th check if going to the next step is allowed
     * 
     * @param {object} state 
     * @param {boolean} valid
     * @param {number} valid 
     */
    updateStepValidity(state, {valid, stepIndex}) {
      const step = state.steps[stepIndex];
      if (!step) return false;
      step.valid = valid;
    },

    // Add the `updateField` mutation to the
    // `mutations` of your Vuex store instance.
    updateField,
  },
});