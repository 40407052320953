<template>
  <div>
    <step-intro iframe-url="">
      <p>We need a proper PDF to be able to make a nice looking PDF layout. In this step we will explain you how to prepare your Word document for a PDF layout and how to export it in the right way.</p>
    </step-intro>
    <box title="Prepare your Word document">
      <h3 class="title is-3">General</h3>
      <p>To create a nice PDF layout you have to check the structure of your Word document. We will explain you how to prepare your Word document. In most cases you only have to change a few things but it is important to check. We can not guarantee a nice PDF layout if you deliver the PDF file in the wrong way. Please watch the instruction video below.</p>
      <div class="iframe-container"><iframe src="https://player.vimeo.com/video/393676721" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
      <h4 class="title-is-4"><br>Add in a temporary cover page</h4>
      <p>Every printed book starts with a page on the right. From the second page on it consists of facing pages. Because Word is not suitable for book productions we have to add in a temporary cover page to ‘fake’ the set-up of a book. In this way we get a nice look on our left and right pages in the final book.</p>
      <h4 class="title-is-4">Contents of the first pages</h4>
      <p>In almost every thesis the following set-up of the first pages is used: <br>
      page 1: Title page Example (large tool tip with screenshot)<br>
      page 2: Colofon Example (large tool tip with screenshot)<br>
      page 3: Promotion page from the university Example (large tool tip with screenshot)<br>
      page 4: Committee Example (large tool tip with screenshot)<br>
      page 5: Table of contents Example (large tool tip with screenshot)<br></p>
      <h4 class="title-is-4">Page numbers in table of contents</h4>
      <p>Make sure to check your page numbers in the table of contents afterwards. We will put in your table of contents including the page numbers the way you provide it to us.</p>
      <h4 class="title-is-4">Add in or change the chapter and part pages</h4>
      <p>Make sure every chapter and part starts with a blank page on the left and your title on the right (odd) page. Your appendices also need 2 pages to start with, similar to your chapter pages.</p>
      <h4 class="title-is-4">Set page size to A4</h4>
      <p>Make sure all your pages are A4 or have the custom size of 170mm x 240mm. We will scale them down to the size of your thesis. If there are for instance any A3 pages in your PDF you will not be able to read the text after scaling.
      <h4 class="title-is-4">Check the inner margins</h4>
      <p>Use the standard setting for margins. In this way you text will be placed in the best way in your PDF layout. Make sure the inner margins do not contain any important content.</p>
      <h4 class="title-is-4">Scaling and recommended font size</h4>
      <p>Your A4 pages will be scaled down to the size of a thesis. Therefor we recommend a fontsize of 11 or 12 in Word.</p>
      <h4 class="title-is-4">Rotated / landscape pages</h4>
      <p>You can keep rotated pages in your thesis but if you have headings and/or page numbers you have to manually change the position on the rotated pages. In your PDF layout we will rotate your page automatically, if you keep your headings and page number in there they will be positioned vertically on the left and right side of your page.</p>
       <h4 class="title-is-4">Do not add in blank pages at the end</h4>
      <p>You don't have to add in blank pages add the end of your document. We will automatically add the necessary blank pages. The very last page of your thesis will always be blank. If you end on an even page with your content we will add in 2 blank pages, when you end on an odd page we will add in 1 blank page.</p>
      <h4 class="title-is-4">Before export, delete the cover page</h4>
      <p>Before you export your PDF from Word, do not forget to remove the temporary cover page.</p>
      <template v-if="headerAndPageNumbers">
        <h3 class="title is-3">Headings and pagenumbers</h3>
        <p>You choose to add headings and page numbers from one of our templates. To make sure your PDF layout will look nice you have to remove all headings and page numbers in Word. When we create your PDF layout we will add in the headers and page numbers. In step 5 you will provide us with the text your wish to put in your headings.</p>
        <div class="iframe-container"><iframe src="https://player.vimeo.com/video/393676633" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
     </template>
      <template v-if="chapterPages">
        <h3 class="title is-3">Chapter pages</h3>
        <p>You choose to add chapter pages from one of our templates. To make sure these chapter pages will look nice it is important to leave your current pages in your PDF. We will put our template chapter pages on top of your pages. In step 5 you will provide us with the information on your chapter page. This consists of the chapter title, the authors and details about the publication.</p>
        <p>If you want to put an image on every left page of your chapter pages please upload this image to WeTransfer together with your PDF.</p>
        <p><strong>IMPORTANT</strong> Make sure there is no other information on your current chapter pages in Word. This will be overwritten with the information you provide in step 5.</p>
        <div class="iframe-container"><iframe src="https://player.vimeo.com/video/393676575" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
     </template>
       <h4 class="title-is-4"><br>Export your Word document to PDF</h4>
      <p>To export your Word document to PDF please take a look at our <a href="https://www.ridderprint.nl/en/customer-service/create-pdf/" target="_blank">instructions.</a></p>
    </box>
    <box title="PDF File">
      <p>
        The PDF file has to be perfectly correct, otherwise we can’t create a PDF layout.
      </p>
      <div class="field">
        <b-checkbox v-model="watchedVideo">I have watched the instruction video(s).</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="chaptersUneven">I made all the necessary changes to my Word document.</b-checkbox>
      </div>
      <div class="field">
        <b-checkbox v-model="layoutCorrect">I exported my Word document to PDF according to the instructions.</b-checkbox>
      </div>
      <hr>
      <div class="columns is-vcentered">
        <div class="column is-narrow has-text-grey-dark">Upload your final PDF file</div>
        <div class="column">
          <b-field>
            <b-button type="is-primary" @click.prevent="showUploadField = true" :disabled="!userCanUploadFile">Upload file
            </b-button>
          </b-field>
        </div>
      </div>
      <div v-show="showUploadField">
        <validation-observer tag="div" :step-index="stepIndex">
          <p>Upload a new file to <b-tooltip multilined label="Select 'Transfer link' instead of 'E-mail transfer'."><a href="https://www.wetransfer.com" target="_blank">WeTransfer</a></b-tooltip> and paste the url in the field below.</p>
          <validation-provider rules="required|url" v-slot="{ errors }">
            <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                <b-input icon="link" placeholder="https://www.wetransfer.com" v-model="thesisFile" placetype="url"></b-input>
            </b-field>
          </validation-provider>
        </validation-observer>
      </div>
    </box>
  </div>

</template>

<script>
import { mapFields } from 'vuex-map-fields';
import box from './../components/box.vue';
import stepIntro from './../components/step-intro.vue';
import ValidationObserver from './../components/validation-observer.vue';

export default {
    props: ['stepIndex'],
    components: {
        box,
        stepIntro,
        ValidationObserver
    },
    computed: {
        ...mapFields([
          'thesisFile',
          'uploadRequirements.watchedVideo',
          'uploadRequirements.chaptersUneven',
          'uploadRequirements.layoutCorrect',
          'templateOptions.elements.headerAndPageNumbers',
          'templateOptions.elements.tabs',
          'templateOptions.elements.chapterPages'
        ]),
        /**
         * Only allow file upload if all checkboxes are checked
         */
        userCanUploadFile() {
          return this.$store.state.uploadRequirements.watchedVideo && this.$store.state.uploadRequirements.chaptersUneven && this.$store.state.uploadRequirements.layoutCorrect;
        }
    },
    data() {
      return {
        showUploadField: false
      }
    }
}
</script>

<style>

</style>