<template>
  <div>
    <step-intro iframe-url="">
      <p>Please provide detailed information on the structure of your thesis. In this way we now exactly where to place the different elements and information you will provide in step 5.</p>
    </step-intro>
    <validation-observer tag="div" :step-index="stepIndex">
      <box v-if="chapters.length > 0" title="Chapters">
        <div class="columns">
          <div class="column is-one-quarter"></div>
          <div class="column is-one-quarter">
           <b-tooltip multilined type="is-white" label="Enter the page number of the page where your chapter title is placed. This can only be a right page with an uneven page number. If this is not the case, please take a closer look at the instructions in ‘step 2 - Upload’."><a @click.prevent="" href="#">Page number of title page</a></b-tooltip>
          </div>
          <div class="column is-one-quarter">
            <b-tooltip multilined type="is-white" label="Example: With 3 subchapters we mean ‘2.1, 2.2, 2.3’"><a @click.prevent="" href="#">Subchapter</a></b-tooltip>
          </div>
        </div>
        <div v-for="(chapter, index) in chapters" :key="index" class="columns-group">
          <div class="columns">
            <div class="column is-one-quarter">
              <span class="input input--fake">
                  Chapter {{index + 1}}<span v-if="chapter.subChapters && chapter.subChapters.length > 0">.1</span>
              </span>
            </div>
            <div class="column is-one-quarter">
              <validation-provider rules="higher_than:0|odd" v-slot="{ errors }">
                  <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                      <b-input v-model="chapter.firstPage" type="number"></b-input>
                  </b-field>
              </validation-provider>
            </div>
            <div class="column is-one-quarter" v-if="chapter.subChapters">
              <b-select expanded :value="chapter.subChapters.length" @input="updateSubChapterAmount($event, index)">
                <option value="0">No subchapters</option>
                <option v-for="option in 9" :value="option" :key="option">
                  {{ option + 1 }} subchapters
                </option>
              </b-select>
            </div>
          </div>
          <template v-if="chapter.subChapters && chapter.subChapters.length > 0">
            <div class="columns" v-for="(subchapter, subChapterIndex) in chapter.subChapters" :key="subChapterIndex">
              <div class="column is-one-quarter">
                <span class="input input--fake">Chapter {{index + 1}}.{{subChapterIndex + 2}}</span>
              </div>
              <div class="column is-one-quarter">
                <validation-provider rules="higher_than:0|odd" v-slot="{ errors }">
                  <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                      <b-input v-model="subchapter.firstPage" type="number"></b-input>
                  </b-field>
                </validation-provider>
              </div>
            </div>
          </template>        
        </div>
      </box>
      <box v-if="parts.length > 0" title="Parts">
        <div class="columns">
          <div class="column is-one-quarter"></div>
          <div class="column is-one-quarter">
            First chapter of this part
          </div>
        </div>
        <div class="columns" v-for="(part, index) in parts" :key="index">
          <div class="column is-one-quarter">
            <span class="input input--fake" v-if="partsNotion ==='roman'">Part {{ index + 1 | romanize }}</span>
            <span class="input input--fake" v-else>Part {{ index + 1 }}</span>
          </div>
          <div class="column is-one-quarter">
            <b-select expanded v-model="part.startsWithChapter">
              <option v-for="option in chapters.length" :value="option - 1" :key="option">
                Chapter {{ option }}
              </option>
            </b-select>
          </div>
        </div>
      </box>
      <box v-if="appendices.length > 0" title="Appendices">
        <div class="columns">
          <div class="column is-8">
            <b-tooltip multilined type="is-white" label="When you choose to add headings and page numbers this title will appear in the heading. When you choose to add chapter pages these titles will appear on the chapter page of your appendices. We you only choose tabs, the appendice titels are not shown.
"><a @click.prevent="" href="#">Title of appendix</a></b-tooltip>
          </div>
          <div class="column is-2">
            First page
          </div>
          <div class="column is-2">
            Last page
          </div>
        </div>
        <div class="columns" v-for="(appendice, index) in appendices" :key="index">
          <div class="column is-8">
            <b-input v-if="chapterPages || headerAndPageNumbers" :value="appendice.title" @input="updateAppendiceHeader($event, index)" type="text"></b-input>
            <span v-else class="input input--fake">Appendice {{ index + 1 }}</span>
          </div>
          <div class="column is-2">
            <validation-provider rules="higher_than:0" :name="'first_page' + index" v-slot="{ errors }">
              <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                <b-input v-model="appendice.firstPage" type="number" min="1"></b-input>
              </b-field>
            </validation-provider>

          </div>
          <div class="column is-2">
            <validation-provider :rules="'last_page:@first_page' + index" v-slot="{ errors }">
              <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                <b-input v-model="appendice.lastPage" type="number"></b-input>
              </b-field>
            </validation-provider>
          </div>
        </div>
      </box>
    </validation-observer>
     <b-modal :active.sync="firstPageModal" :width="800">
        <div class="card">
            <div class="card-image">
                <img src="@/assets/chapter-pages.jpg">
            </div>
            <div class="card-content">
                <div class="content">
                    <p>Enter the page number of the page where your chapter title is placed. This can only be an uneven number. Is your chapter title on an even page number? Please take a closer look at ‘step 2 - Upload’.</p>
                </div>
            </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import stepIntro from './../components/step-intro.vue';
import box from './../components/box.vue';
import ValidationObserver from './../components/validation-observer.vue';

export default {
    props: ['stepIndex'],
    components: {
        stepIntro,
        box,
        ValidationObserver
    },
    data() {
      return {
        firstPageModal: false,
      }
    },
    computed: {
      ...mapFields(['structure.chapters', 'structure.partsNotion', 'structure.parts','structure.appendices', 'templateOptions.elements.chapterPages', 'templateOptions.elements.headerAndPageNumbers']),
    },
    methods: {

      /**
       * Update the array of subschapters based on the input number value
       */
      updateSubChapterAmount(amount, parentChapterIndex) {
        this.$store.commit('updateSubChapterAmount', {amount, parentChapterIndex});
      },

      /**
       * Commit update of the appendice header
       */
      updateAppendiceHeader(value, appendiceIndex) { 
        this.$store.commit('updateAppendiceHeader', {
          index: appendiceIndex,
          value: value
        });
      }
    }
}
</script>
<style>
  .columns-group:not(:last-child) {
    margin-bottom: calc(1.5rem - 0.75rem);
  }
</style>