<template>
  <div id="app" ref="app">
    <div v-if="!completed">
      <b-steps :value="activeStep" @input="stepChanged($event)" :animated="false">
        <template v-for="(step, index) in steps">
          <b-step-item :key="index" :label="step.label" :visible="step.visible" :clickable="allowThisStep(index)">
             <component :is="step.type" :step-index="index"></component>
          </b-step-item>
        </template>
        <template slot="navigation" slot-scope="{previous, next}">
          <div class="step__navigation">
            <b-button class="is-black" @click.prevent="previous.action">
                Previous
            </b-button>
            <b-button class="is-primary" :disabled="!steps[activeStep].valid || formIsSubmitting" @click.prevent="sendRequest" v-if="activeStep === 5">
              Submit PDF layout
            </b-button>
            <b-button class="is-black" :disabled="isNextStepDisabled" @click.prevent="next.action" v-else>
                Next
            </b-button>
          </div>
        </template>
      </b-steps>
    </div>
    <div v-else>
      <completed></completed>
    </div>
  </div>
</template>

<script>
import thesisTemplate from './steps/template.vue';
import upload from './steps/upload.vue';
import structure from './steps/structure.vue';
import contents from './steps/contents.vue';
import thesisDetails from './steps/details.vue';
import contact from './steps/contact.vue';
import completed from './steps/completed.vue';


export default {
  name: 'app',
  components: {
    thesisTemplate,
    upload,
    structure,
    contents,
    thesisDetails,
    contact,
    completed
  },

  computed: {
    steps() {
      return this.$store.state.steps;
    },
    activeStep() {
      return this.$store.state.activeStep;
    },
    biggestStep() {
      return this.$store.state.biggestStep;
    },
    isNextStepDisabled() {
      if (this.steps[this.activeStep].type !== 'structure') return !this.steps[this.activeStep].valid;
      return !this.steps[this.activeStep].valid;
    }
  },

  data() {
    return {
      completed: false,
      formIsSubmitting: false
    }
  },

  methods: {
    
    /**
     * Send the JSON 
     */
    sendRequest() {
      const dataObject = {
        user: {
          firstName:  this.$store.state.contact.firstName, 
          lastName:  this.$store.state.contact.lastName,
          email:  this.$store.state.contact.email,
          remarks: this.$store.state.contact.remarks,
          orderNumber: this.getOrderNumberFromUrl()
        },
        file:  this.$store.state.thesisFile,
        indesignTask: this.$store.getters.indesignJSON,
        copyright: {
          rights: 'Copyright (C) ' + (new Date().getFullYear()) + ' InDesign Toolkit - All Rights Reserved',
          statement: 'Unauthorized copying of this file, via any medium is strictly prohibited',
          author: 'Robbert de Vries / Robbert de Kuiper',
          email: 'robbert@indesigntoolkit.com'
        }
      };

      if (process && process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
        dataObject.debug = true;
      }

      this.formIsSubmitting = true;
      fetch('https://diy.persoonlijkproefschrift.nl/mail/', {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataObject)
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.completed = true;         
        } else {
          Promise.reject('backend sent no success message');
        }
        this.formIsSubmitting = false;
      })
      .catch(error => {
        alert('Something went wrong, try again later')
        // eslint-disable-next-line
        console.error(error);
        this.formIsSubmitting = false;
      })
    },

    getOrderNumberFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('ordernummer')) {
        return urlParams.get('ordernummer');
      }
      return null;
    },

    allowThisStep(index) {
      // Not been here before, not valid
      if (this.biggestStep < index) return false;
      // Check if al previous steps are valid
      const previousSteps = this.steps.slice(0, index);
      const previousStepsValid = previousSteps.every(step => step.valid);
      return previousStepsValid;
    },

    stepChanged(event) {
      if(event > this.biggestStep) {
        this.$store.commit('updateBiggestStep', event)
      }
      const elementAbove = document.querySelector("menubar")
      if (elementAbove) elementAbove.scrollIntoView();
      this.$store.commit('updateActiveStep', event);
    }
  }
}
</script>

<style lang="scss">
// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

/*
 * BULMA OVERRIDES
 */
.button {
  font-weight: bold;
}

hr {
  margin: 1rem 0 1.5rem 0;
}

:root .title {
  margin-bottom: 5px;
  color: black;
}

/*
 * Genereal styling
 */
html {
  font-size: 14px;
  background: #dfdfdf;
}

body {
  background: #dfdfdf;
}

#app {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 900px;
  margin: 0 auto;
  padding: 3rem 3rem 0 3rem;
}

i, em {
  font-weight: 400;
}

label,
label.label {
  font-weight: 600;
}

strong {
    font-weight: 800;
}

:root .title-is-4 {
  font-weight: 800;
  margin: 0;
  padding: 0;
}

.b-steps .steps .step-items {
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.b-steps .steps .step-items .step-item {
  flex-grow: 0;
  padding-left: 10px;
  padding-right: 10px;
}


.b-steps .steps .step-items .step-item .step-marker {
  display: none;
}

.b-steps .steps .step-items .step-item::before,
.b-steps .steps .step-items .step-item::after {
  background: none;
}

.b-steps .steps .step-items .step-item .step-details {
  margin: 0;
}

.b-steps .steps .step-items .step-item .step-link .step-details .step-title {
  @extend .button;
  @extend .button.is-black;
  cursor: not-allowed;
  font-size: 1rem;
}

.b-steps .steps .step-items .step-item .step-link.is-clickable .step-details .step-title {
  @extend .button.is-primary;
  cursor: pointer;
  opacity: 0.5;
}

.b-steps .steps .step-items .step-item.is-active .step-link.is-clickable .step-details .step-title {
  opacity: 1;
}

.step__navigation {
  padding: 0 1rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
}

.input--fake {
  background: $white-ter;
  box-shadow: none;

  &:hover {
    border-color: #dbdbdb;
  }
}

:root .button--fake {
   &:hover {
    background-color: $primary;
  }
}

.columns.is-vbottom {
  align-items: flex-end;
}

:root .field--small {
  label {
    margin-bottom: 2px;
  }
}

.pb-0 {
  padding-bottom: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.b-tooltip.is-multiline::after {
  padding: 0.5rem 0.75rem
}

.b-tooltip.is-right.is-multiline::after {
  text-align: left;
}

</style>
