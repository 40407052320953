/**
 * Helpers functions to convert the store to a JSON object that can be imported in Indesign
 */

/**
 * Blanco part starts at page 1 and ends two pages before the first chapter start
 * 
 * @param {number} firstChapterStartPage First page of the next chapter
 */
export function createBlancoPart(firstChapterStartPage) {
    return {
        pages: '1-' + (firstChapterStartPage - 2),
        type: 'blanco'
    }
}

/**
 * Create a chapter intro spread
 * This spread is always placed before the real chapters begins
 * 
 * @param {object} chapter
 * @param {number} chapterNumber
 * @param {boolean} isSubChapter 
 * @param {number} subChapterIndex 

 */
export function createChapterIntro(chapter, chapterIndex,  isSubChapter = false, subChapterIndex = 0) {
    if (!chapter && !chapterIndex) return {}
    const chapterNumber = isSubChapter ? `${chapterIndex + 1}.${subChapterIndex + 2}` : chapter.subChapters.length ? `${chapterIndex + 1}.1`:  chapterIndex + 1;

    return {
        // The intro starts one page before the chapter starts
        pages: (parseInt(chapter.firstPage) - 1) + '-' + chapter.firstPage,
        type: 'chapter page',
        'chapter-name': 'Chapter',
        'chapter-number': chapterNumber,
        'chapter-id': chapterIndex + 1,
        'chapter-title': chapter.title,
        'chapter-authors': chapter.authors,
        'chapter-published': chapter.publisher
    }
}

/**
 * Create a chapter
 *  
 * @param {object} chapter 
 * @param {number} chapterIndex 
 * @param {object} state 
 * @param {boolean} isSubChapter 
 * @param {number} subChapterIndex 
 */
export function createChapter(chapter, chapterIndex, state, isSubChapter = false, subChapterIndex = 0) {
    if (!chapter && !chapterIndex) return {}
    const chapterEndPage = findchapterEndPage(chapter, chapterIndex, state, isSubChapter, subChapterIndex)

    return {
        // The intro starts one page before the chapter starts
        pages: (parseInt(chapter.firstPage) + 1) + '-' + chapterEndPage,
        type: 'chapter',
        'chapter-id': chapterIndex + 1,
        'chapter-title-left': chapter.leftHeader,
        'chapter-title-right':chapter.rightHeader
     }

}

/**
 * Create an appendix intro spread
 * 
 * @param {object} state
 */
export function createAppendixIntro(state) {
    if (!state.structure.appendices.length) return {};
    const firstAppendix = state.structure.appendices[0] 
    const appendicesTitles = state.structure.appendices.map(appendix => appendix.title).join('\r')
    return {
        pages: (parseInt(firstAppendix.firstPage) - 2) + '-' + (parseInt(firstAppendix.firstPage) - 1),
        type: 'appendix page',
        'chapter-name': 'Appendices',
        'chapter-authors': '',
        'chapter-published': '',
        'chapter-number': '',
        'chapter-id': 'A',
        'chapter-title': appendicesTitles,
    }
}

/**
 * Create an appendix
 * 
 * @param {object} appendix
 */
export function createAppendix(appendix) {
    return {
        // The intro starts one page before the chapter starts
        pages: appendix.firstPage + '-' + appendix.lastPage,
        type: 'appendix',
        'chapter-id': 'A',
        'chapter-title-left': appendix.leftHeader,
        'chapter-title-right': appendix.rightHeader
     }
}

/**
 * Output a part page
 * 
 * @param {object} nextChapter The first chapter in the part
 * @param {number} partIndex 
 * @param {object} state 
 */
export function createPart(nextChapter, partIndex, state) {
    partIndex = parseInt(partIndex);
    const nextChapterStartPage = parseInt(nextChapter.firstPage);
    const part = state.structure.parts[partIndex];
    return {
        pages: (nextChapterStartPage - 3) + '-' + (nextChapterStartPage - 2),
        type: 'part page',
        'chapter-name': 'Part', 
        'chapter-number': state.structure.partsNotion === 'roman' ? romanize(partIndex + 1) : partIndex + 1,
        'chapter-title': part.title  
    }
}

/**
 * Get the part that starts with the current chapter
 * 
 * @param {number} chapterIndex index of the current chapter
 * @param {object} state ref to state
 * @return {number|boolean} the index of the part, false if not found
 */
export function getPartIndexThatStartWithThisChapter(chapterIndex, state) {
    // No parts, don't look
    if (state.structure.parts.length < 1) return false;
    // Loop through the parts, find the part that has this chapter as prop
    const partIndex =  state.structure.parts.findIndex(part => part.startsWithChapter === chapterIndex );
    return partIndex !== -1 ? partIndex : false;
}

/**
 * Find the chapters endpage by checking what is following the chapter
 * 
 * @param {object} chapter 
 * @param {number} chapterIndex 
 * @param {object} state 
 * @param {boolean} isSubChapter 
 * @param {number} subChapterIndex 
 */
function findchapterEndPage(chapter, chapterIndex, state, isSubChapter, subChapterIndex) {
    // If this is a subchapter, check if there is a next subchapter
    if (isSubChapter && subChapterIndex >= 0) {
        const nextSubChapter = state.structure.chapters[chapterIndex].subChapters[subChapterIndex + 1] || false;
        if (nextSubChapter) {
            return nextSubChapter.firstPage - 2; // Chapter ends before the intro spread
        }
    }

    // If not a subchapter, check if there are subschapters and get the first one
    if (chapter.subChapters && chapter.subChapters.length) {
        return chapter.subChapters[0].firstPage - 2; // Chapter ends before the intro spread
    }

    // Find the next chapter, if there is one, get that one
    const nextChapter = state.structure.chapters[chapterIndex + 1];


    if (nextChapter) {
        // If the next chapter is the starts of a new part the chapter ends before the part intro spread,
        // Which starts two before the intro spread of the next chapter
        const nextChapterStartsWithPart =  state.structure.parts.find(part => part.startsWithChapter === chapterIndex + 1 );
        return nextChapterStartsWithPart ? nextChapter.firstPage - 4 : nextChapter.firstPage - 2; // Chapter ends before the intro spread
    }

    // Check if there are appendices and get the first one, this the chapter ends before the appendix intro spread
    if (state.structure.appendices && state.structure.appendices[0]) return state.structure.appendices[0].firstPage - 3;

    // This is the end
    return state.structure.pagesAmount;
}

/**
 * Converts an arabic number to the roman equalivant
 * 
 * @param {number} num 
 * @return {string} roman digit, e.g. I IV VI
 */
function romanize(num) {
    const lookup = {X:10,IX:9,V:5,IV:4,I:1};
    let roman = '';
    let i;
    for ( i in lookup ) {
      while ( num >= lookup[i] ) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
}