import Vue from 'vue';
import App from './App.vue';
import Buefy from 'buefy';
import store from "./store";
import { ValidationProvider } from 'vee-validate';
import "./vee-validate";
import { iframeResizerContentWindow } from 'iframe-resizer';

iframeResizerContentWindow;

Vue.config.productionTip = false

Vue.use(Buefy);

Vue.component('ValidationProvider', ValidationProvider);

/*
* Output the number in roman style
* Example: 3 -> III
*/
Vue.filter('romanize', function(num) {
  const lookup = {X:10,IX:9,V:5,IV:4,I:1};
  let roman = '';
  let i;
  for ( i in lookup ) {
    while ( num >= lookup[i] ) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
});

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
