<template>
  <div>
    <div v-if="headerAndPageNumbers || chapterPages">
      <step-intro iframe-url="">
      <p>You choose to add in chapter pages and / or headings and page numbers. We need to know what text you want to put on these pages. Please fill in the form below.</p>
      </step-intro>
      <validation-observer tag="div" :step-index="stepIndex">
        <div class="columns is-vcentered">
          <div class="column is-narrow is-relative">
            <b-tooltip multilined size="is-large" position="is-right" type="is-white" label="All pages before chapter 1 never contain headings and page numbers. Nevertheless the page number count will start on the first page. This means the first visible page number can never be 1. With a standard set-up your first visible page number will be between 8 and 12.">
              <span class="button button--fake is-primary">Blanco</span>
            </b-tooltip>
          </div>
          <div class="column">
            <p>The pages before Chapter 1 never contain elements like tabs, headers or pagenumbers.</p>
          </div>
        </div>
        <div v-for="(chapter, chapIndex) in chapters" :key="'blanco-' + chapIndex">
          <template v-if="isBlancoChapter(chapIndex)">
            <template v-if="chapter.subChapters && chapter.subChapters.length > 0">
              <chapter-description :stepIndex="stepIndex" :index="chapIndex" :chapter="chapter" :title="'Chapter ' + (chapIndex + 1) + '.1'"></chapter-description>
              <chapter-description :stepIndex="stepIndex" v-for="(subChapter, subChapIndex) in chapter.subChapters" :title="'Chapter ' + (chapIndex + 1) + '.' + (subChapIndex + 2)" :key="subChapIndex" :index="subChapIndex" :chapter="subChapter"></chapter-description>
            </template>
            <chapter-description v-else :stepIndex="stepIndex" :index="chapIndex" :chapter="chapter"></chapter-description>
          </template>
        </div>  
        <div v-for="(part, partIndex) in parts" :key="partIndex">
          <div class="columns is-vbottom">
            <div class="column is-narrow is-relative">
                <b-tooltip multilined size="is-large" position="is-right" type="is-white" label="For every new part we will replace the 2 part pages. These are similar to your chapter pages but only contain the part number and the title of the part.">
                  <span class="button button--fake is-primary" v-if="partsNotion ==='roman'">Part {{ partIndex + 1 | romanize }}</span>
                  <span class="button button--fake is-primary" v-else>Part {{ partIndex + 1 }}</span>
                </b-tooltip>
            </div>
            <div class="column">
              <b-field class="field--small field--part" label="Part title">
                <b-input type="text" v-model="part.title"></b-input>
              </b-field>
            </div>
          </div>
          
          <div v-for="(chapter, chapIndex) in chapters" :key="partIndex + '-' + chapIndex">
            <template v-if="isInCurrentPart(chapIndex, partIndex)">
              <template v-if="chapter.subChapters && chapter.subChapters.length > 0">
                <chapter-description :stepIndex="stepIndex" :index="chapIndex" :chapter="chapter" :title="'Chapter ' + (chapIndex + 1) + '.1'"></chapter-description>
                <chapter-description :stepIndex="stepIndex" v-for="(subChapter, subChapIndex) in chapter.subChapters" :title="'Chapter ' + (chapIndex + 1) + '.' + (subChapIndex + 2)" :key="subChapIndex" :index="subChapIndex" :chapter="subChapter"></chapter-description>
              </template>
              <chapter-description v-else :stepIndex="stepIndex" :index="chapIndex" :chapter="chapter"></chapter-description>
            </template>
          </div>  
        </div>
        <template v-if="appendices.length > 0">
          <div class="columns is-vcentered">
            <div class="column is-narrow is-relative">
              <b-tooltip multilined size="is-large" position="is-right" type="is-white" label="Before your appendices we will replace the 2 ‘chapter’ pages. On this page we will place ‘Appendices’ with a list of your appendices. We will use the titles you have chosen in step 4. You can choose different headings if you would like.">
              <span class="button button--fake is-primary">Appendices</span>
            </b-tooltip>
            </div>
            <div class="column">
              The title of the appendices ‘chapter page’ will contain a list of all appendices.
            </div>
          </div>
          <div v-for="(appendice, appendiceIndex) in appendices" :key="'appendices-' + appendiceIndex">
            <box :title="appendice.title">
                <div class="field">
                  <div class="columns mb-0">
                    <div class="column pb-0">
                      <label>Left header <span class="has-text-grey">(maximum of 80 characters incl. spaces)</span></label>
                    </div>
                    <div class="column is-narrow pb-0">
                      <char-counter class="u-text-right" :chars-used="appendice.leftHeader.length" total-chars="80"></char-counter>
                    </div>
                  </div>
                  <validation-provider rules="max:80" v-slot="{ errors }">
                      <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                          <b-input v-model="appendice.leftHeader"></b-input>
                      </b-field>
                  </validation-provider>
                </div>
                <div class="field">
                  <div class="columns mb-0">
                    <div class="column pb-0">
                      <label>Right header <span class="has-text-grey">(maximum of 80 characters incl. spaces)</span></label>
                    </div>
                    <div class="column is-narrow pb-0">
                      <char-counter class="u-text-right" :chars-used="appendice.rightHeader.length" total-chars="80"></char-counter>
                    </div>
                  </div>
                  <validation-provider rules="max:80" v-slot="{ errors }">
                      <b-field :type="{ 'is-danger': errors[0]}" :message="errors">
                          <b-input v-model="appendice.rightHeader"></b-input>
                      </b-field>
                  </validation-provider>
                </div>
            </box>
          </div>
        </template>
      </validation-observer> 
    </div>
    <div v-else>
      <step-intro>
        With only tabs selected as the template element, there is no need to fill in the details. Please continue.
      </step-intro>
    </div>
  </div>

</template>

<script>
import { mapFields } from 'vuex-map-fields';
import box from './../components/box.vue';
import chapterDescription from './../components/chapter-description.vue';
import stepIntro from './../components/step-intro.vue';
import charCounter from './../components/char-counter.vue';
import ValidationObserver from './../components/validation-observer.vue';

export default {
    props: ['stepIndex'],
    components: {
      box,
      stepIntro,
      chapterDescription,
      charCounter,
      ValidationObserver
    },
    data() {
      return {
      }
    },
    computed: {
        ...mapFields(['templateOptions.elements.headerAndPageNumbers', 'templateOptions.elements.chapterPages', 'structure.partsNotion', 'structure.chapters', 'structure.parts', 'structure.appendices']),
    },
    methods: {
      /*
       * Check if the chapter is before the first part
       */
      isBlancoChapter(index) {
        
        if (!this.parts.length) return true;
        const startFirstPart = this.getPartStartingChapter(0);
        return index < startFirstPart;
      },

      /*
       * Check if the chapter is in the current part
       */ 
      isInCurrentPart(chapterIndex, partIndex) {
        const partStartsWithChapter = this.getPartStartingChapter(partIndex);
        // Don't look up next part if this is the last item
        if (partIndex + 1 === this.parts.length) {
          return chapterIndex >= partStartsWithChapter;
        }

        const nextPartStartsWithChapter = this.getPartStartingChapter(partIndex + 1);
        return chapterIndex >= partStartsWithChapter && chapterIndex < nextPartStartsWithChapter;
      },

      /**
       * Get the starting chapter property of a part
       * Based on part index
       * 
       * @param {number} index
       * @return {number} index of the starting chapter
       */
      getPartStartingChapter(index) {
         if (!this.parts || !this.parts[index] || !this.parts[index].startsWithChapter) return 0;
         return this.parts[index].startsWithChapter;
      }
    }
}
</script>
<style>

.is-relative {
  position: relative;
}

.button--fake + .c-box__information {
  top: 11px;
}

:root:root .field--part label{
  line-height: 1.2;
  margin-bottom: 0;
  margin-left: 8px;
  color: black;
}
</style>